import React from 'react'
import Img from 'gatsby-image'
import {FiBriefcase, FiMail, FiMap} from 'react-icons/fi'
import {Title, Text} from '../utils'

const TeamSection = ({heading, label, team}) => {
  return (
    <div className='container my-12 mx-auto px-4 md:px-12'>
      {/* <div className='relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl mb-10'>
        <GroupTitle label={label} title={heading} />
      </div> */}
      {/* {team.map(item => {
        return (
          <div className='my-2 sm:my-1 px-1 w-full md:w-1/3 lg:my-4 lg:px-4 lg:w-1/4'>
            <article className='overflow-hidden rounded shadow-md bg-white'>
              <Img fluid={item.image.asset.fluid} alt={item.image.alt} className='block h-auto w-full rounded-b-none' />
              <header className='flex-col items-center justify-between leading-tight p-2 md:p-4'>
                <SmallHeading className='truncate'>{item.name}</SmallHeading>
                <Text className='truncate-2-lines'>
                  {item.bioNew}
                </Text>
              </header>
            </article>
          </div>
        )
      })} */}
      <div className='max-w-3xl flex items-center h-auto lg:h-auto flex-wrap mx-auto my-32 lg:my-0'>
        {/* <!--Main Col--> */}
        <div
          id='profile'
          className='w-full lg:w-3/5 rounded lg:rounded-l-lg lg:rounded-r-none shadow-lg bg-white opacity-75 mx-1 md:mx-6 lg:mx-0'
        >
          <div className='p-4 md:p-8 text-center lg:text-left'>
            {/* <!-- Image for mobile view--> */}
            <div className='block lg:hidden rounded-full shadow-xl mx-auto -mt-16 h-48 w-48 bg-cover bg-center' style={{backgroundImage: `url('https://res.cloudinary.com/danielkapper-com/image/upload/v1584983915/49256474_10218714097030554_1619941130089005056_o.jpg_szwvwp.jpg')`}} />
            <Title>{team[0].name}</Title>
            <div className='mx-auto lg:mx-0 w-4/5 pt-3 border-b-2 border-indigo-500 opacity-25' />
            <p className='pt-2 text-gray-600 text-base font-bold flex items-center justify-start pl-6 lg:pl-0 lg:justify-start'>
              <span className='pr-4'>
                <FiBriefcase className='text-indigo-700 text-2xl' />
              </span>
              {team[0].position}
            </p>
            <p className='pt-2 text-gray-600 text-base flex items-center justify-start pl-6 lg:pl-0 lg:justify-start'>
              <span className='pr-4'>
                <FiMap className='text-indigo-700 text-2xl' />
              </span>
              {team[0].location}
            </p>
            <p className='pt-2 text-gray-600 text-base flex items-center justify-start pl-6 lg:pl-0 lg:justify-start'>
              <span className='pr-4'>
                <FiMail className='text-indigo-700 text-2xl' />
              </span>
              {team[0].email}
            </p>
            <Text>{team[0].bioNew}</Text>
            <div className='mt-6 pb-16 lg:pb-0 w-4/5 lg:w-full mx-auto flex flex-wrap items-center justify-start'>
              <a className='link mr-5' href='https://github.com/dkapper01' target='_blank' data-tippy-content='@github_handle'>
                <svg
                  className='h-6 fill-current text-gray-600 hover:text-indigo-700'
                  role='img'
                  viewBox='0 0 24 24'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <title>GitHub</title>
                  <path d='M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12' />
                </svg>
              </a>
              <a className='link' href='https://twitter.com/dkapper01' target='_blank' data-tippy-content='@twitter_handle'>
                <svg
                  className='h-6 fill-current text-gray-600 hover:text-indigo-700'
                  role='img'
                  viewBox='0 0 24 24'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <title>Twitter</title>
                  <path d='M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z' />
                </svg>
              </a>
            </div>
          </div>
        </div>

        {/* <!--Img Col--> */}
        <div className='w-full lg:w-2/5'>
          <Img
            fluid={team[0].image.asset.fluid}
            alt={team[0].image.alt}
            className='rounded-none lg:rounded shadow-md hidden lg:block'
          />
        </div>
      </div>
    </div>
  )
}

export default TeamSection
