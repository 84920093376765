import React from 'react'
import {Label, Title, Subtitle} from '../utils'

const GroupTitle = ({label, title, subtitle}) => {
  return (
    <div className='lg:text-center'>
      {label && <Label>{label}</Label>}
      <Title>{title || 'undefined'}</Title>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </div>
  )
}

export default GroupTitle
