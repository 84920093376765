import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

const SplitCtaImage = ({
  label,
  heading,
  description,
  image,
  button,
  blogPost,
}) => {
  return (
    <div className="relative bg-gray-800">
      <div className="h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
        <Img
          alt={blogPost.mainImage.alt}
          fluid={blogPost.mainImage.asset.fluid}
          className="w-full h-full object-cover"
        />
      </div>
      <div className="relative max-w-screen-xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
        <div className="md:ml-auto md:w-1/2 md:pl-10">
          <div className="text-base leading-6 font-semibold uppercase tracking-wider text-gray-300">
            {blogPost.categories[0].title} ·&nbsp;Featured
          </div>
          <h2 className="mt-2 text-white text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">
            {blogPost.title}
          </h2>
          <p className="mt-3 text-lg leading-7 text-gray-300">
            {blogPost.excerptNew}
          </p>
          <div className="mt-8">
            <div className="inline-flex rounded-full shadow">
              <Link
                to={`blog/${blogPost.slug.current}`}
                className="inline-flex items-center justify-center no-underline px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-full text-gray-900 bg-white hover:text-gray-600 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
              >
                Learn more
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SplitCtaImage
