import React from 'react'
import PropTypes from 'prop-types'
import {Text, SmallHeading, CheckMark, GroupTitle} from '../utils'

const FourColSection = ({label, title, subtitle, details}) => {
  return (
    <div className='py-12 bg-gray-50 mx-auto lg:w-11/12'>
      <div className='max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8'>
        <GroupTitle label={label} title={title} subtitle={subtitle} />
        <div className='mt-10'>
          <ul className='md:grid md:grid-cols-2 md:col-gap-8 md:row-gap-10'>
            {details.map(item => {
              return (
                <li key={item._key} className='list-none'>
                  <div className='flex'>
                    <div className='flex-shrink-0'>
                      <CheckMark />
                    </div>
                    <div className='ml-4'>
                      <SmallHeading>{item.heading}</SmallHeading>
                      <Text>{item.description}</Text>
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}

FourColSection.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  details: PropTypes.arrayOf(PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string
  }))
}

export default FourColSection
