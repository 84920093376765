import React from "react"
import Img from "gatsby-image"
import { GroupTitle } from "../utils"

const LogoGrid = ({ heading, logo }) => {
  return (
    // <div className='bg-white mx-auto lg:w-11/12'>
    <div className="py-16  overflow-hidden lg:py-16">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl mb-10">
        <GroupTitle
          label="Technology we use"
          title="Rebuilding The Internet"
          subtitle="These tools support the JAMstack and are designed to make highly performant websites and apps."
        />
      </div>
      <div className="max-w-screen-xl mx-auto py-10 px-4 sm:px-6 lg:py-5 lg:px-8 -mt-5">
        <p className="text-center text-base leading-6 font-semibold uppercase text-gray-600 tracking-wider">
          {/* {heading} */}api based services
        </p>
        <div className="mt-6 grid grid-cols-2 gap-2 md:grid-cols-4 lg:mt-4 max-w-3xl mx-auto">
          {/* {logo.map(item => {
            return (
              <div key={item._key} className='col-span-1 flex justify-center py-8 px-8 bg-gray-50 '>
                <Img fluid={item.asset.fluid} alt={item.alt} className='max-h-12 w-full ' />
              </div>
            )
          })} */}
          <div
            key={logo[9]._key}
            className="shadow-lg col-span-1 flex justify-center items-center py-4 px-4 bg-white rounded-md"
          >
            <Img
              fluid={logo[9].asset.fluid}
              alt={logo[9].alt}
              className="max-h-16 w-40"
            />
          </div>
          <div
            key={logo[10]._key}
            className="shadow-lg col-span-1 flex justify-center items-center py-4 px-4 bg-white rounded-md "
          >
            <Img
              fluid={logo[10].asset.fluid}
              alt={logo[10].alt}
              className="max-h-12 w-40"
            />
          </div>
          <div
            key={logo[14]._key}
            className="shadow-lg col-span-1 flex justify-center items-center py-4 px-4 bg-white rounded-md"
          >
            <Img
              fluid={logo[14].asset.fluid}
              alt={logo[14].alt}
              className="max-h-16 w-40"
            />
          </div>
          <div
            key={logo[13]._key}
            className="shadow-lg col-span-1 flex justify-center items-center py-4 px-4 bg-white rounded-md"
          >
            <Img
              fluid={logo[13].asset.fluid}
              alt={logo[13].alt}
              className="max-h-16 w-40"
            />
          </div>
        </div>
      </div>

      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-5 lg:px-8">
        <p className="text-center text-base leading-6 font-semibold uppercase text-gray-600 tracking-wider">
          {/* {heading} */}front end build
        </p>
        <div className="mt-6 grid grid-cols-2 gap-2 md:grid-cols-4 lg:mt-4 max-w-3xl mx-auto">
          {/* {logo.map(item => {
            return (
              <div key={item._key} className='col-span-1 flex justify-center py-8 px-8 bg-gray-50 '>
                <Img fluid={item.asset.fluid} alt={item.alt} className='max-h-12 w-full ' />
              </div>
            )
          })} */}
          {/* #a1a1a1 */}
          <div
            key={logo[0]._key}
            className="shadow-lg col-span-1 flex justify-center items-center py-4 px-4 bg-white rounded-md"
          >
            <Img
              fluid={logo[0].asset.fluid}
              alt={logo[0].alt}
              className="max-h-16 w-40"
            />
          </div>
          <div
            key={logo[1]._key}
            className="shadow-lg col-span-1 flex justify-center items-center py-4 px-4 bg-white rounded-md"
          >
            <Img
              fluid={logo[1].asset.fluid}
              alt={logo[1].alt}
              className="max-h-12 w-32 "
            />
          </div>
          <div
            key={logo[2]._key}
            className="shadow-lg col-span-1 flex justify-center items-center py-4 px-4 bg-white rounded-md "
          >
            <Img
              fluid={logo[2].asset.fluid}
              alt={logo[2].alt}
              className="max-h-12 w-12 sm:max-h-14 sm:w-16"
            />
          </div>
          <div
            key={logo[3]._key}
            className="shadow-lg col-span-1 flex justify-center items-center py-4 px-4 bg-white rounded-md"
          >
            <Img
              fluid={logo[3].asset.fluid}
              alt={logo[3].alt}
              className="max-h-12 w-40 "
            />
          </div>
          {/* <div
            key={logo[4]._key}
            className="col-span-1 flex justify-center items-center py-4 px-4 bg-white rounded-md"
          >
            <Img
              fluid={logo[4].asset.fluid}
              alt={logo[4].alt}
              className="max-h-16 w-24 "
            />
          </div> */}
        </div>
      </div>

      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-5 lg:px-8">
        <p className="text-center text-base leading-6 font-semibold uppercase text-gray-600 tracking-wider">
          {/* {heading} */}CDN & SERVERLESS
        </p>
        <div className="mt-6 grid grid-cols-2 gap-2 md:grid-cols-3 lg:mt-4 max-w-2xl mx-auto">
          {/* {logo.map(item => {
            return (
              <div key={item._key} className='col-span-1 flex justify-center py-8 px-8 bg-gray-50 '>
                <Img fluid={item.asset.fluid} alt={item.alt} className='max-h-12 w-full ' />
              </div>
            )
          })} */}
          <div
            key={logo[5]._key}
            className="shadow-lg col-span-1 flex justify-center items-center py-4 px-4 bg-white rounded-md"
          >
            <Img
              fluid={logo[5].asset.fluid}
              alt={logo[5].alt}
              className="max-h-16 w-40"
            />
          </div>
          <div
            key={logo[6]._key}
            className="shadow-lg col-span-1 flex justify-center items-center py-4 px-4 bg-white rounded-md"
          >
            <Img
              fluid={logo[15].asset.fluid}
              alt={logo[15].alt}
              className="max-h-12 w-64"
            />
          </div>
          {/* <div
            key={logo[7]._key}
            className="col-span-1 flex justify-center items-center py-4 px-4 bg-white rounded-md"
          >
            <Img
              fluid={logo[7].asset.fluid}
              alt={logo[7].alt}
              className="max-h-16 w-40"
            />
          </div> */}
          <div
            // key={logo[8]._key}
            className="shadow-lg col-span-1 flex justify-center items-center py-4 px-4 bg-white rounded-md"
          >
            {/* <Img
              fluid={logo[8].asset.fluid}
              alt={logo[8].alt}
              className="max-h-12 w-12"
            /> */}
            <svg width="85%" height="46px" viewBox="0 0 230 46" version="1.1">
              <title>Black Full Logo</title>
              <desc>Created with Sketch.</desc>
              <defs>
                <linearGradient
                  x1="114.720775%"
                  y1="181.283245%"
                  x2="39.5399306%"
                  y2="100%"
                  id="linearGradient-1"
                >
                  <stop stop-color="#a1a1a1" offset="0%"></stop>
                  <stop stop-color="#a1a1a1" offset="100%"></stop>
                </linearGradient>
              </defs>
              <g
                id="Page-1"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="Black-Full-Logo-"
                  transform="translate(-235.a1a1a1, -177.a1a1a1)"
                  fill-rule="nonzero"
                >
                  <g
                    id="Black-Full-Logo"
                    transform="translate(235.a1a1a1, 177.a1a1a1)"
                  >
                    <polygon
                      id="Triangle"
                      fill="url(#linearGradient-1)"
                      points="25.49162 0 50.98324 45.226415 0 45.226415"
                    ></polygon>
                    <path
                      d="M85.75,34 L106.2,34 L106.2,30.45 L90.85,30.45 L105.95,8.9 L105.95,5.8 L86,5.8 L86,9.35 L100.85,9.35 L85.75,30.9 L85.75,34 Z M127.6,34 L145.95,34 L145.95,30.45 L131.75,30.45 L131.75,21.4 L144.1,21.4 L144.1,17.85 L131.75,17.85 L131.75,9.35 L145.95,9.35 L145.95,5.8 L127.6,5.8 L127.6,34 Z M168.05,34 L185.95,34 L185.95,30.45 L179.1,30.45 L179.1,9.35 L185.95,9.35 L185.95,5.8 L168.05,5.8 L168.05,9.35 L174.95,9.35 L174.95,30.45 L168.05,30.45 L168.05,34 Z M215.4,34 L219.55,34 L219.55,9.35 L229.15,9.35 L229.15,5.8 L205.9,5.8 L205.9,9.35 L215.4,9.35 L215.4,34 Z"
                      id="ZEIT"
                      fill="#a1a1a1"
                    ></path>
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LogoGrid
