import React from "react"
import { Link } from "gatsby"

type Props = {
  button: {
    title: string
    route: {
      slug: {
        current: string
      }
    }
  }
  titleBlack?: string
}

const JustifiedCta = ({ titleBlack, button }: Props) => {
  return (
    <div className="bg-gray-50">
      <div className="max-w-6xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
          {titleBlack ? titleBlack : "Ready to get started?"}
        </h2>
        <div className="mt-8 flex lg:flex-shrink-0 lg:mt-0">
          <div className="inline-flex rounded-full shadow">
            <Link
              to={`/${button.route.slug.current}`}
              className="inline-flex items-center justify-center no-underline px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
            >
              {button.title}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default JustifiedCta
