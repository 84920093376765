import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { Text, SmallHeading, GroupTitle, Label } from "../utils"

const BlogSectionList = ({ heading, label, blog }) => {
  return (
    <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-16 lg:pb-28 lg:px-8">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl mb-10">
        <GroupTitle label={label} title={heading} />
      </div>

      <div className="relative max-w-6xl mx-auto">
        <div className="mt-12 grid gap-5 max-w-lg mx-auto lg:grid-cols-3 lg:max-w-none">
          {blog.map(item => {
            return (
              <div className="flex flex-col rounded-md shadow-lg overflow-hidden bg-white">
                <Link
                  to={`/blog/${item.slug.current}`}
                  className="no-underline"
                >
                  <div className="flex-shrink-0">
                    <Img
                      fluid={item.mainImage.asset.fluid}
                      alt={item.mainImage.alt}
                      className="h-48 w-full object-cover"
                    />
                  </div>
                  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                      <p className="text-sm leading-5 font-medium text-indigo-600">
                        {item.categories[0].title}
                      </p>
                      <div className="block">
                        <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
                          {item.title}
                        </h3>
                        <p className="mt-3 text-base leading-6 text-gray-500">
                          {item.excerptNew}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default BlogSectionList
