import React from "react"
import Img from "gatsby-image"
import { Text, SmallHeading, CheckMark } from "../utils"

const TwoColByTwoRow = ({
  title,
  subtitle,
  descriptionOne,
  descriptionTwo,
  detailsOne,
  detailsTwo,
  headingOne,
  headingTwo,
  imageOne,
  imageTwo,
}) => {
  return (
    <div className="py-16 bg-gray-50 overflow-hidden lg:py-16">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-0 lg:max-w-6xl	">
        <div className="relative">
          <h3 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            {title}
          </h3>
          <p className="mt-4 max-w-3xl mx-auto text-center text-xl leading-7 text-gray-500">
            {subtitle}
          </p>
        </div>

        <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="relative">
            <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
              {headingOne}
            </h4>
            <p className="mt-3 text-lg leading-7 text-gray-500">
              {descriptionOne}
            </p>

            <ul className="mt-10">
              {detailsOne.map(item => {
                return (
                  <li key={item._key} className="list-none">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <CheckMark />
                      </div>
                      <div className="ml-4">
                        <SmallHeading>{item.heading}</SmallHeading>
                        <Text>{item.description}</Text>
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
          <div className="mt-10 -mx-4 relative lg:mt-0">
            <Img
              fluid={imageOne.asset.fluid}
              alt={imageOne.alt}
              className="relative mx-auto rounded-md w-11/12 md:w-10/12"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TwoColByTwoRow
