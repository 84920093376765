import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
// import route from '../../../studio/schemas/documents/route'

const TwoCol = ({ pageOne, pageTwo }) => {
  return (
    <div className="py-16 overflow-hidden lg:py-24">
      {/* <div className="relative max-w-md mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-5xl">
        <div className="flex flex-wrap overflow-hidden">
          <div className="border-2 border-transparent hover:border-gray-100 hover:bg-gray-50 rounded-md w-full overflow-hidden sm:w-1/2 md:w-full lg:w-full xl:w-1/2">
            <Link to={`/${pageOne.route.slug.current}`}>
              <div className="p-0 md:p-5">
                <Img
                  fluid={pageOne.openGraphImage.asset.fluid}
                  className="rounded"
                />
                <h2 className="text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                  {pageOne.title}
                </h2>
                <p className="mt-1 max-w-2xl text-xl leading-7 text-gray-500">
                  {pageOne.description}
                </p>
              </div>
            </Link>
          </div>
          <div className="border-2 border-transparent hover:border-gray-100 hover:bg-gray-50 rounded-md w-full overflow-hidden sm:w-1/2 md:w-full lg:w-full xl:w-1/2">
            <Link to={`/${pageTwo.route.slug.current}`}>
              <div className="p-0 md:p-5">
                <Img
                  fluid={pageTwo.openGraphImage.asset.fluid}
                  className="rounded"
                />
                <h2 className="text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                  {pageTwo.title}
                </h2>
                <p className="mt-1 max-w-2xl text-xl leading-7 text-gray-500">
                  {pageTwo.description}
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default TwoCol
