import React from 'react'

const Title = ({children, className = ''}) => {
  return (
    <h3 className={`${className} mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10`}>
      {children}
    </h3>
  )
}

export default Title
