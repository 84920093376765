import React from "react"
import { FiCheckCircle } from "react-icons/fi"
import Img from "gatsby-image"

const SideBySideImage = ({ heading, description, details, image }) => {
  return (
    <div className="py-16 bg-gray-50 overflow-hidden lg:py-20">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
        <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="relative">
            <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
              {heading}
            </h4>
            <p className="mt-3 text-lg leading-7 text-gray-500">
              {description}
            </p>
            <ul className="mt-10">
              {details.map(item => {
                return (
                  <li key={item._key}>
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                          <FiCheckCircle className="h-6 w-6" />
                        </div>
                      </div>
                      <div className="ml-4">
                        <h5 className="text-lg leading-6 font-medium text-gray-900">
                          {item.heading}
                        </h5>
                        <p className="mt-2 mb-9 text-base leading-6 text-gray-500">
                          {item.description}
                        </p>
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>

          <div className="mt-10 -mx-4 relative lg:mt-0">
            <Img
              fluid={image.asset.fluid}
              alt={image.alt}
              className="relative mx-auto rounded-md w-11/12 md:w-4/5"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SideBySideImage
