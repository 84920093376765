import React from 'react'

const StatsColored = () => {
  return (
    <div className='bg-indigo-800'>
      <div className='max-w-screen-xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8 lg:py-20'>
        <div className='max-w-4xl mx-auto text-center'>
          <h2 className='text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10'>
            Trusted by developers from over 80 planets
          </h2>
          <p className='mt-3 text-xl leading-7 text-indigo-200 sm:mt-4'>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repellendus repellat laudantium.
          </p>
        </div>
        <div className='mt-10 text-center sm:max-w-3xl sm:mx-auto sm:grid sm:grid-cols-3 sm:gap-8'>
          <div>
            <p className='text-5xl leading-none font-extrabold text-white'>
              100%
            </p>
            <p className='mt-2 text-lg leading-6 font-medium text-indigo-200'>
              Pepperoni
            </p>
          </div>
          <div className='mt-10 sm:mt-0'>
            <p className='text-5xl leading-none font-extrabold text-white'>
              24/7
            </p>
            <p className='mt-2 text-lg leading-6 font-medium text-indigo-200'>
              Delivery
            </p>
          </div>
          <div className='mt-10 sm:mt-0'>
            <p className='text-5xl leading-none font-extrabold text-white'>
              100k+
            </p>
            <p className='mt-2 text-lg leading-6 font-medium text-indigo-200'>
              Calories
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StatsColored
