import React from 'react'

const TitleAndSubtitle = ({title, subtitle}) => {
  return (
    <div className='py-16 bg-gray-50 overflow-hidden lg:py-20'>
      <div className='relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl'>

        <div className='relative'>
          <h3 className='text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10'>
            {title}
          </h3>
          <p className='mt-4 max-w-3xl mx-auto text-center text-xl leading-7 text-gray-500'>
            {subtitle}
          </p>
        </div>
      </div>
    </div>
  )
}

export default TitleAndSubtitle
