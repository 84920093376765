import React from 'react'

const StatsSplitImage = () => {
  return (
    <div className='relative bg-white'>
      <div className='h-56 bg-indigo-600 sm:h-72 lg:absolute lg:left-0 lg:h-full lg:w-1/2'>
        <img
          className='w-full h-full object-cover'
          src='https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80'
          alt='Support team'
        />
      </div>
      <div className='relative max-w-screen-xl mx-auto px-4 py-8 sm:py-12 sm:px-6 lg:py-16'>
        <div className='max-w-2xl mx-auto lg:max-w-none lg:mr-0 lg:ml-auto lg:w-1/2 lg:pl-10'>
          <div>
            <div className='flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white'>
              <svg className='h-6 w-6' stroke='currentColor' fill='none' viewBox='0 0 24 24'>
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z'
                />
              </svg>
            </div>
          </div>
          <h2 className='mt-6 text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10'>
            Deliver what your customers want every time
          </h2>
          <p className='mt-6 text-lg leading-7 text-gray-500'>
            Lorem ipm dolor, sit amet consectetur adipisicing elit. Dolore nihil ea rerum ipsa.
            Nostrum consectetur sequi culpa doloribus omnis, molestiae esse placeat, exercitationem
            magnam quod molestias quia aspernatur deserunt voluptatibus.
          </p>
          <div className='mt-8 overflow-hidden'>
            <div className='-mx-8 -mt-8 flex flex-wrap'>
              <div className='px-8 pt-8'>
                <p className='text-2xl leading-8 font-extrabold text-indigo-600 sm:text-3xl sm:leading-9'>
                  24/7
                </p>
                <p className='text-base leading-6 font-medium text-gray-500'>Delivery</p>
              </div>
              <div className='px-8 pt-8'>
                <p className='text-2xl leading-8 font-extrabold text-indigo-600 sm:text-3xl sm:leading-9'>
                  99.9%
                </p>
                <p className='text-base leading-6 font-medium text-gray-500'>Pepperoni</p>
              </div>
              <div className='px-8 pt-8'>
                <p className='text-2xl leading-8 font-extrabold text-indigo-600 sm:text-3xl sm:leading-9'>
                  100k+
                </p>
                <p className='text-base leading-6 font-medium text-gray-500'>Calories</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StatsSplitImage
