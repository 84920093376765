import React from 'react'
import {FiCheckCircle} from 'react-icons/fi'

const CheckMark = ({className = ''}) => {
  return (
    <div className={`${className} text-indigo-600 text-2xl`}>
      <FiCheckCircle />
    </div>

  )
}

export default CheckMark
