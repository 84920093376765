import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { Label } from "../utils"

const SplitHero = ({
  label,
  titleWhite,
  titleColor,
  description,
  backgroundImage,
}) => {
  return (
    <div className="relative bg-white overflow-hidden mx-auto lg:w-11/12 bg-gray-50">
      <div className="relative pt-0 pb-16 md:pb-10 lg:pb-10">
        <div className="mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8">
            <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
              <Label
                color="gray"
                className="sm:text-base lg:text-sm xl:text-base"
              >
                {label}
              </Label>
              <h2 className="mt-1 text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:leading-none sm:text-6xl lg:text-5xl xl:text-6xl">
                {/* <span className="text-black">{titleColor}</span>
                <br className="hidden md:inline" />
                Secure
                <br className="hidden md:inline" />
                Fast */}
                {/* The Modern architecture   */}A modern approach to web
                development.
              </h2>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                {description}
              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-full shadow">
                  <Link
                    to="/project-estimate"
                    className="w-full flex items-center justify-center no-underline px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                  >
                    Get started
                  </Link>
                </div>
              </div>
            </div>
            <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
              <div className="relative mx-auto w-full rounded-md shadow-lg lg:max-w-4xl">
                <div className="relative block w-full rounded-md overflow-hidden">
                  <Img
                    fluid={backgroundImage.asset.fluid}
                    alt={backgroundImage.alt}
                    className="w-full"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SplitHero
