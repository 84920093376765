import React from 'react'
import {Text, SmallHeading, CheckMark} from '../utils'

const OffSetGrid = ({heading, detailsOne}) => {
  return (
    <div className='bg-gray-50 overflow-hidden'>
      <div className='relative max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:px-8'>
        <div className='relative lg:grid lg:grid-cols-3 lg:col-gap-8'>
          <div className='lg:col-span-1'>
            <h3 className='text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10'>
              {heading}
            </h3>
          </div>
          <div className='mt-10 sm:grid sm:grid-cols-2 sm:col-gap-8 sm:row-gap-10 lg:col-span-2 lg:mt-0'>
            {detailsOne.map(item => {
              return (
                <div>
                  <CheckMark />
                  <div className='mt-5'>
                    <SmallHeading>{item.heading}</SmallHeading>
                    <Text>
                      {item.description}
                    </Text>
                  </div>
                </div>

              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default OffSetGrid
