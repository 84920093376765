import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

const LogoSplit = ({ heading, description, button, logo }) => {
  return (
    <div class="bg-gray-50">
      <div class="max-w-6xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <h2 class="text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
          Frequently asked questions
        </h2>
        <div class="mt-6 border-t-2 border-gray-200 pt-6">
          <dl>
            <div class="md:grid md:grid-cols-12 md:gap-8">
              <dt class="text-base leading-6 font-medium text-gray-900 md:col-span-5">
                How long does a project take to complete?
              </dt>
              <dd class="mt-2 md:mt-0 md:col-span-7">
                <p class="text-base leading-6 text-gray-500">
                  Depending on the size of the project it will take three to
                  nine weeks.
                </p>
              </dd>
            </div>
            <div class="mt-8 border-t border-gray-200 pt-6 md:grid md:grid-cols-12 md:gap-8">
              <dt class="text-base leading-6 font-medium text-gray-900 md:col-span-5">
                How do you make holy water?
              </dt>
              <dd class="mt-2 md:mt-0 md:col-span-7">
                <p class="text-base leading-6 text-gray-500">
                  You boil the hell out of it. Lorem ipsum dolor sit amet
                  consectetur adipisicing elit. Magnam aut tempora vitae odio
                  inventore fuga aliquam nostrum quod porro. Delectus quia
                  facere id sequi expedita natus.
                </p>
              </dd>
            </div>
            <div class="mt-8 border-t border-gray-200 pt-6 md:grid md:grid-cols-12 md:gap-8">
              <dt class="text-base leading-6 font-medium text-gray-900 md:col-span-5">
                What do you call someone with no body and no nose?
              </dt>
              <dd class="mt-2 md:mt-0 md:col-span-7">
                <p class="text-base leading-6 text-gray-500">
                  Nobody knows. Lorem ipsum dolor sit amet consectetur
                  adipisicing elit. Culpa, voluptas ipsa quia excepturi,
                  quibusdam natus exercitationem sapiente tempore labore
                  voluptatem.
                </p>
              </dd>
            </div>
            <div class="mt-8 border-t border-gray-200 pt-6 md:grid md:grid-cols-12 md:gap-8">
              <dt class="text-base leading-6 font-medium text-gray-900 md:col-span-5">
                What is the least spoken language in the world?
              </dt>
              <dd class="mt-2 md:mt-0 md:col-span-7">
                <p class="text-base leading-6 text-gray-500">
                  Sign language. Lorem ipsum dolor sit amet consectetur
                  adipisicing elit. Quas cupiditate laboriosam fugiat.
                </p>
              </dd>
            </div>
            <div class="mt-8 border-t border-gray-200 pt-6 md:grid md:grid-cols-12 md:gap-8">
              <dt class="text-base leading-6 font-medium text-gray-900 md:col-span-5">
                Why can't you hear a pterodactyl go to the bathroom?
              </dt>
              <dd class="mt-2 md:mt-0 md:col-span-7">
                <p class="text-base leading-6 text-gray-500">
                  Because the pee is silent. Lorem ipsum dolor sit amet,
                  consectetur adipisicing elit. Ipsam, quas voluptatibus ex
                  culpa ipsum, aspernatur blanditiis fugiat ullam magnam
                  suscipit deserunt illum natus facilis atque vero consequatur!
                  Quisquam, debitis error.
                </p>
              </dd>
            </div>
            <div class="mt-8 border-t border-gray-200 pt-6 md:grid md:grid-cols-12 md:gap-8">
              <dt class="text-base leading-6 font-medium text-gray-900 md:col-span-5">
                Why did the invisible man turn down the job offer?
              </dt>
              <dd class="mt-2 md:mt-0 md:col-span-7">
                <p class="text-base leading-6 text-gray-500">
                  He couldn't see himself doing it. Lorem ipsum dolor sit, amet
                  consectetur adipisicing elit. Eveniet perspiciatis officiis
                  corrupti tenetur. Temporibus ut voluptatibus, perferendis sed
                  unde rerum deserunt eius.
                </p>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  )
}

export default LogoSplit
