import React from 'react'

const colors = {
  default: `text-base text-indigo-600`,
  gray: `text-sm text-gray-500`
}

const Label = ({children, className = '', color}) => {
  return (
    <p
      className={`${className} ${colors[color] ||
        colors.default} leading-6 font-semibold tracking-wide uppercase`}
    >
      {children}
    </p>
  )
}

export default Label
