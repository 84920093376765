import React from 'react'

const Text = ({children, className = ''}) => {
  return (
    <div className={`${className} mt-2 mb-2 text-base leading-6 text-gray-500`}>{children}</div>

  )
}

export default Text
