import React from "react"
import { navigate } from "gatsby"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default function Contact() {
  const [state, setState] = React.useState({})

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => window.alert(error))
  }

  return (
    <>
      <div class="relative bg-white">
        <div class="lg:absolute lg:inset-0">
          <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <img
              class="h-56 w-full object-cover lg:absolute lg:h-full"
              src="https://res.cloudinary.com/danielkapper-com/image/upload/v1586378599/photo-1576961457745-955300ee1a71_flhk7b.jpg"
              alt=""
            />
          </div>
        </div>
        <div class="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
          <div class="lg:pr-8">
            <div class="max-w-md mx-auto sm:max-w-lg lg:mx-0">
              <h2 class="text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">
                Let's work together
              </h2>
              <p class="mt-4 text-lg leading-7 text-gray-500 sm:mt-3">
                We’d love to hear from you! Send us a message using the form
                opposite, or email us. We’d love to hear from you! Send us a
                message using the form opposite, or email us.
              </p>
              <form
                name="contact"
                method="post"
                action="/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
                class="mt-9 grid grid-cols-1 row-gap-6 sm:grid-cols-2 sm:col-gap-8"
              >
                <input type="hidden" name="form-name" value="contact" />
                <p hidden>
                  <label>
                    Don’t fill this out:{" "}
                    <input name="bot-field" onChange={handleChange} />
                  </label>
                </p>
                <div>
                  <label
                    for="first_name"
                    class="block text-sm font-medium leading-5 text-gray-700"
                  >
                    First name
                  </label>
                  <div class="mt-1 relative rounded-md shadow-sm">
                    <input
                      onChange={handleChange}
                      name="firstname"
                      type="text"
                      id="first_name"
                      className="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    />
                  </div>
                </div>
                <div>
                  <label
                    for="last_name"
                    class="block text-sm font-medium leading-5 text-gray-700"
                  >
                    Last name
                  </label>
                  <div class="mt-1 relative rounded-md shadow-sm">
                    <input
                      type="text"
                      name="lastname"
                      onChange={handleChange}
                      id="last_name"
                      class="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    />
                  </div>
                </div>
                <div class="sm:col-span-2">
                  <label
                    for="email"
                    class="block text-sm font-medium leading-5 text-gray-700"
                  >
                    Email
                  </label>
                  <div class="mt-1 relative rounded-md shadow-sm">
                    <input
                      id="email"
                      type="email"
                      name="email"
                      onChange={handleChange}
                      class="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    />
                  </div>
                </div>
                <div class="sm:col-span-2">
                  <label
                    for="company"
                    class="block text-sm font-medium leading-5 text-gray-700"
                  >
                    Company
                  </label>
                  <div class="mt-1 relative rounded-md shadow-sm">
                    <input
                      id="company"
                      type="text"
                      name="company"
                      onChange={handleChange}
                      class="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    />
                  </div>
                </div>
                {/* <div class="sm:col-span-2">
                  <div class="flex justify-between">
                    <label
                      for="phone"
                      class="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Phone
                    </label>
                    <span class="text-sm leading-5 text-gray-500">
                      Optional
                    </span>
                  </div>
                  <div class="mt-1 relative rounded-md shadow-sm">
                    <input
                      type="tel"
                      name="phone"
                      onChange={handleChange}
                      id="phone"
                      class="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    />
                  </div>
                </div> */}
                <div class="sm:col-span-2">
                  <div class="flex justify-between">
                    <label
                      for="how_can_we_help"
                      class="block text-sm font-medium leading-5 text-gray-700"
                    >
                      How can we help you?
                    </label>
                    <span class="text-sm leading-5 text-gray-500">
                      Max. 500 characters
                    </span>
                  </div>
                  <div class="mt-1 relative rounded-md shadow-sm">
                    <textarea
                      id="how_can_we_help"
                      rows="4"
                      name="message"
                      onChange={handleChange}
                      class="form-textarea block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    ></textarea>
                  </div>
                </div>
                {/* <fieldset class="sm:col-span-2">
                  <legend class="block text-sm font-medium leading-5 text-gray-700">
                    Expected budget
                  </legend>
                  <div class="mt-4 grid grid-cols-1 row-gap-4">
                    <div class="flex items-center">
                      <input
                        id="budget_under_25k"
                        name="budget"
                        value="under_25k"
                        type="radio"
                        class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                      />
                      <label for="budget_under_25k" class="ml-3">
                        <span class="block text-sm leading-5 text-gray-700">
                          Less than $5K
                        </span>
                      </label>
                    </div>
                    <div class="flex items-center">
                      <input
                        id="budget_25k-50k"
                        name="budget"
                        value="25k-50k"
                        type="radio"
                        class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                      />
                      <label for="budget_25k-50k" class="ml-3">
                        <span class="block text-sm leading-5 text-gray-700">
                          $5K – $15K
                        </span>
                      </label>
                    </div>
                    <div class="flex items-center">
                      <input
                        id="budget_50k-100k"
                        name="budget"
                        value="50k-100k"
                        type="radio"
                        class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                      />
                      <label for="budget_50k-100k" class="ml-3">
                        <span class="block text-sm leading-5 text-gray-700">
                          $15 – $25K
                        </span>
                      </label>
                    </div>
                    <div class="flex items-center">
                      <input
                        id="budget_over_100k"
                        name="budget"
                        value="over_100k"
                        type="radio"
                        class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                      />
                      <label for="budget_over_100k" class="ml-3">
                        <span class="block text-sm leading-5 text-gray-700">
                          $25K+
                        </span>
                      </label>
                    </div>
                  </div>
                </fieldset> */}
                {/* <div class="sm:col-span-2">
                  <label
                    for="how_did_you_hear_about_us"
                    class="block text-sm font-medium leading-5 text-gray-700"
                  >
                    How did you hear about us?
                  </label>
                  <div class="mt-1 relative rounded-md shadow-sm">
                    <input
                      onChange={handleChange}
                      type="text"
                      name="how_did_you_hear_about_us"
                      id="how_did_you_hear_about_us"
                      class="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    />
                  </div>
                </div> */}
                <div class="text-right sm:col-span-2">
                  <span class="flex rounded-full shadow-sm">
                    <button
                      type="submit"
                      class="w-full flex justify-center py-3 px-4 border border-transparent text-base leading-5 font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                    >
                      Request Free Consultation Now!
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* old form below */}
      {/* <div className="h-screen max-h-screen flex-col pt-16">
        <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl mb-10">
          <div className="lg:text-center">
            <p className="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
              Let's Work Together
            </p>
            <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
              Start your super-fast project
            </h3>
          </div>
        </div>
        <div className=" w-11/12 md:w-1/2 mx-auto flex justify-center">
          <form
            name="contact"
            method="post"
            action="/thanks/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
            className="w-full md:max-w-lg"
          >
            <input type="hidden" name="form-name" value="contact" />
            <p hidden>
              <label>
                Don’t fill this out:{" "}
                <input name="bot-field" onChange={handleChange} />
              </label>
            </p>
            <label className="block">
              <span className="hidden">Your email</span>
              <input
                className="form-input mt-1 block w-full  appearance-none block bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                type="text"
                placeholder="Your name"
                name="name"
                onChange={handleChange}
              />
            </label>
            <label className="block">
              <span className="hidden">Your email</span>
              <input
                className="form-input mt-1 block w-full  appearance-none block bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                type="email"
                name="email"
                placeholder="Your email"
                onChange={handleChange}
              />
            </label>
            <div className="mt-4">
              <div className="my-2">
                <span className=" inline-block text-sm leading-2 font-medium text-gray-600  align-middle mb-2 pr-4">
                  Type of project:
                </span>
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    className=""
                    name="website"
                    value="personal"
                  />
                  <span className="ml-2 text-sm leading-2 font-medium text-gray-600">
                    Website
                  </span>
                </label>
                <label className="inline-flex items-center ml-6">
                  <input
                    type="radio"
                    className=""
                    name="eCommerce"
                    value="personal"
                  />
                  <span className="ml-2 text-sm leading-2 font-medium text-gray-600">
                    eCommerce
                  </span>
                </label>
                <label className="inline-flex items-center ml-6">
                  <input
                    type="radio"
                    className=""
                    name="Other"
                    value="personal"
                  />
                  <span className="ml-2 text-sm leading-2 font-medium text-gray-600">
                    Other
                  </span>
                </label>
              </div>
            </div>
            <label className="block">
              <span className="hidden">Your message</span>
              <textarea
                className="form-textarea mt-1 block w-full appearance-none block bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                name="message"
                placeholder="How can we help?"
                onChange={handleChange}
              />
            </label>
            <div className="mt-8 flex justify-center w-full">
              <div className="inline-flex rounded-full shadow w-full">
                <button
                  type="submit"
                  className="w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                >
                  Request Free Consultation Now!
                </button>
              </div>
            </div>
          </form>
        </div>
      </div> */}
    </>
  )
}
