import React, { useState } from "react"
import { FiMail, FiMap } from "react-icons/fi"
import { Link, navigate } from "gatsby"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const ContactPage = ({ title, description, email }) => {
  const [state, setState] = React.useState({})
  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => window.alert(error))
  }
  return (
    <>
      <div className="relative bg-white">
        <div className="absolute inset-0">
          <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50"></div>
        </div>
        <div className="relative max-w-6xl mx-auto lg:grid lg:grid-cols-5">
          <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
            <div className="max-w-lg mx-auto">
              <h2 className="text-2xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl sm:leading-9">
                {title}
              </h2>
              <p className="mt-3 text-lg leading-6 text-gray-500">
                {description}
              </p>
              <dl className="mt-8 text-base leading-6 text-gray-500">
                <div className="mt-6">
                  <dt className="sr-only">Location</dt>
                  <dd className="flex">
                    <FiMap className="text-indigo-700 text-2xl" />

                    <span className="ml-3">Washington, D.C. USA</span>
                  </dd>
                </div>
                <div className="mt-3">
                  <dt className="sr-only">Email</dt>
                  <dd className="flex">
                    <FiMail className="text-indigo-700 text-2xl" />
                    <span className="ml-3">daniel.kapper@jaminary.com</span>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
            <div className="max-w-lg mx-auto lg:max-w-none">
              <form
                name="contact-page"
                method="post"
                action="/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
                className="grid grid-cols-1 row-gap-6"
              >
                <div>
                  <label for="full_name" className="sr-only">
                    Full name
                  </label>
                  <div className="relative rounded-md shadow-sm">
                    <input
                      id="full_name"
                      className="form-input block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150"
                      placeholder="Full name"
                      name="full_name"
                      type="text"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div>
                  <label for="email" className="sr-only">
                    Email
                  </label>
                  <div className="relative rounded-md shadow-sm">
                    <input
                      id="email"
                      type="email"
                      name="email"
                      onChange={handleChange}
                      className="form-input block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150"
                      placeholder="Email"
                    />
                  </div>
                </div>
                <div>
                  <label for="message" className="sr-only">
                    Message
                  </label>
                  <div className="relative rounded-md shadow-sm">
                    <textarea
                      id="message"
                      rows="4"
                      name="message"
                      onChange={handleChange}
                      className="form-input block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150"
                      placeholder="Message"
                    ></textarea>
                  </div>
                </div>
                <div className="">
                  <span className="flex rounded-md shadow-sm w-full">
                    <button
                      type="submit"
                      class="w-full flex justify-center py-3 px-4 border border-transparent text-base leading-5 font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                    >
                      Submit
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactPage
