import React from 'react'

const StaticLogoSection = () => {
  return (
    <div className="py-12 bg-gray-50 overflow-hidden md:py-20 lg:py-24">
      <div className="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative">
          <div className="mt-8">
            <div className="max-w-3xl mx-auto text-center text-4xl leading-9 font-medium text-gray-900">
              {/* <p> */}
              My mission is to help create business value for my clients using the latest technologies.
              {/* </p> */}
            </div>
            {/* <footer className='mt-8'>
              <div className='md:flex md:items-center md:justify-center'>
                <div className='md:flex-shrink-0'>
                  <img className='mx-auto h-10 w-10 rounded-full' src='https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80' alt='' />
                </div>
                <div className='mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center'>
                  <div className='text-base leading-6 font-medium text-gray-900'>Judith Black</div>

                  <svg className='hidden md:block mx-1 h-5 w-5 text-indigo-600' fill='currentColor' viewBox='0 0 20 20'>
                    <path d='M11 0h3L9 20H6l5-20z' />
                  </svg>

                  <div className='text-base leading-6 font-medium text-gray-500'>CEO, Workcation</div>
                </div>
              </div>
            </footer> */}
          </div>
        </div>
      </div>
    </div>
    // <div className='py-12 bg-white'>
    //   <div className='max-w-xl mx-auto px-4 sm:px-6 lg:max-w-screen-xl lg:px-8'>
    //     <div className='lg:grid lg:grid-cols-3 lg:gap-8'>
    //       <div>
    //         <div className='flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white'>
    //           <svg className='h-6 w-6' stroke='currentColor' fill='none' viewBox='0 0 24 24'>
    //             <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9' />
    //           </svg>
    //         </div>
    //         <div className='mt-5'>
    //           <h5 className='text-lg leading-6 font-medium text-gray-900'>Competitive exchange rates</h5>
    //           <p className='mt-2 text-base leading-6 text-gray-500'>
    //         Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.
    //           </p>
    //         </div>
    //       </div>
    //       <div className='mt-10 lg:mt-0'>
    //         <div className='flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white'>
    //           <svg className='h-6 w-6' stroke='currentColor' fill='none' viewBox='0 0 24 24'>
    //             <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3' />
    //           </svg>
    //         </div>
    //         <div className='mt-5'>
    //           <h5 className='text-lg leading-6 font-medium text-gray-900'>No hidden fees</h5>
    //           <p className='mt-2 text-base leading-6 text-gray-500'>
    //         Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.
    //           </p>
    //         </div>
    //       </div>
    //       <div className='mt-10 lg:mt-0'>
    //         <div className='flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white'>
    //           <svg className='h-6 w-6' stroke='currentColor' fill='none' viewBox='0 0 24 24'>
    //             <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M13 10V3L4 14h7v7l9-11h-7z' />
    //           </svg>
    //         </div>
    //         <div className='mt-5'>
    //           <h5 className='text-lg leading-6 font-medium text-gray-900'>Transfers are instant</h5>
    //           <p className='mt-2 text-base leading-6 text-gray-500'>
    //         Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.
    //           </p>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  )
}

export default StaticLogoSection
