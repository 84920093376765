import React from "react"

const SimpleCta = () => {
  return (
    <>
      <div class="pb-12 bg-gray-50 overflow-hidden">
        <div class="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="relative">
            <img
              className="mx-auto w-64"
              src="https://res.cloudinary.com/danielkapper-com/image/upload/c_scale,w_300/v1577156572/logo-one.png"
            />
            <blockquote class="mt-8">
              <div class="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900">
                <p>
                  Working with Daniel improved our website's performance
                  significantly. The user friendly CMS also allows our team to
                  update website content swiftly without asking for any help.
                  JAMstack empowered us to do our jobs better and reach more
                  people.
                </p>
              </div>
              <footer class="mt-8">
                <div class="md:flex md:items-center md:justify-center">
                  <div class="md:flex-shrink-0">
                    <img
                      class="mx-auto h-10 w-10 rounded-full"
                      src="https://res.cloudinary.com/danielkapper-com/image/upload/c_scale,w_100/v1586996080/8a01b3ef70bb99929b4e334ee1fae2c09ef0c39c-1122x1116_uahbcv.png"
                      alt=""
                    />
                  </div>
                  <div class="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                    <div class="text-base leading-6 font-medium text-gray-900">
                      Gulcan Yayla
                    </div>

                    <svg
                      class="hidden md:block mx-1 h-5 w-5 text-indigo-600"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M11 0h3L9 20H6l5-20z" />
                    </svg>

                    <div class="text-base leading-6 font-medium text-gray-500">
                      CEO, Kodluyoruz
                    </div>
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
      </div>
    </>
  )
}

export default SimpleCta
