import React from 'react'

const Subtitle = ({children, className = ''}) => {
  return (
    <p className={`${className} mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto`}>
      {children}
    </p>
  )
}

export default Subtitle
