import React from "react"
import { graphql } from "gatsby"
import Container from "../components/container"
import GraphQLErrorList from "../components/graphql-error-list"
import SEO from "../components/seo"
import Layout from "../containers/layout"
import RenderSections from "../components/RenderSections"


const PageTemplate = props => {
  const { data, errors } = props
  const site = (data || {}).site

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout>
      {/* <SEO
        title={data.content.title}
        description={data.content.description}
        keywords={site.keywords}
        image={data.content.openGraphImage.asset.url}
      /> */}
      <Container>
        <h1 hidden>Welcome to {site.title}</h1>
        {data && <RenderSections sections={data.content.sections} />}
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query PageQuery($id: String!) {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    content: sanityPage(id: { eq: $id }) {
      title
      description
      openGraphImage {
        asset {
          url
        }
      }
      sections {
        ... on SanityCenteredAccordion {
          _key
          _type
          title
        }
        ... on SanityCenteredHero {
          _key
          _type
          title
          subtitle
        }
        ... on SanitySplitHero {
          _key
          _type
          label
          titleWhite
          titleColor
          description
          backgroundImage {
            alt
            asset {
              fluid(maxWidth: 700) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        ... on SanityStaticLogoSection {
          _key
          _type
          title
          subtitle
        }
        ... on SanityBlogSectionList {
          _key
          _type
          heading
          label
          blog {
            id
            publishedAt
            title
            categories {
              title
            }
            slug {
              current
            }
            excerptNew
            _rawExcerpt(resolveReferences: { maxDepth: 10 })
            mainImage {
              asset {
                fluid(maxWidth: 400) {
                  ...GatsbySanityImageFluid
                }
              }
              alt
            }
          }
        }
        ... on SanityTeamSection {
          _key
          _type
          heading
          label
          team {
            name
            bioNew
            email
            position
            location
            image {
              alt
              asset {
                fluid(maxWidth: 600) {
                  ...GatsbySanityImageFluid
                  src
                }
              }
            }
          }
        }
        ... on SanityTwoCol {
          _key
          _type
          pageOne {
            title
            description
           
            openGraphImage {
              asset {
                fluid(maxWidth: 400) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          pageTwo {
            title
            description
        
            openGraphImage {
              asset {
                fluid(maxWidth: 400) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
        ... on SanityContactPage {
          _key
          _type
          title
          description
          email {
            description
            heading
          }
        }
        ... on SanityProjectEstimate {
          _key
          _type
          title
        }
        ... on SanitySideBySideImage {
          _key
          _type
          heading
          description
          image {
            alt
            asset {
              fluid(maxWidth: 500) {
                ...GatsbySanityImageFluid
              }
            }
          }
          details {
            heading
            description
            _key
          }
        }
        ... on SanityTitleAndSubtitle {
          _type
          title
          subtitle
        }
        ... on SanitySideBySideImageLeft {
          _key
          _type
          description
          heading
          details {
            heading
            description
          }
          image {
            _key
            alt
            asset {
              fluid(maxWidth: 500) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        ... on SanitySplitCtaImage {
          _key
          _type
          blogPost {
            slug {
              current
            }
            title
            mainImage {
              asset {
                fluid(maxWidth: 800) {
                  src
                }
              }
            }
            categories {
              title
            }
            excerptNew
          }
        }
        ... on SanityLogoGrid {
          _key
          _type
          heading
          logo {
            _key
            alt
            asset {
              fluid(maxWidth: 200) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        ... on SanityOffSetGrid {
          _key
          _type
          heading
          detailsOne {
            description
            heading
            _key
          }
        }
        ... on SanityTwoColByTwoRow {
          _key
          _type
          descriptionOne
          descriptionTwo
          detailsOne {
            heading
            description
            _key
          }
          detailsTwo {
            _key
            heading
            description
          }
          headingOne
          headingTwo
          imageOne {
            _key
            _type
            alt
            asset {
              fluid(maxWidth: 500) {
                ...GatsbySanityImageFluid
              }
            }
          }
          imageTwo {
            asset {
              fluid(maxWidth: 500) {
                ...GatsbySanityImageFluid
              }
            }
          }
          title
          subtitle
        }
        ... on SanityJustifiedCta {
          _key
          _type
          titleBlack
          button {
            _key
            _type
            title
            route {
              slug {
                current
              }
              page {
                title
              }
            }
          }
        }
        ... on SanitySimpleCta {
          _key
          _type
          title
        }
        ... on SanityLogoSplit {
          _key
          _type
          description
          heading
          button {
            route {
              slug {
                current
              }
            }
            title
          }
          logo {
            _key
            alt
            asset {
              fluid(maxWidth: 200) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        ... on SanityFourColSection {
          _key
          _type
          label
          title
          subtitle
          details {
            _key
            heading
            description
          }
        }
      }
    }
  }
`

export default PageTemplate
