import React from "react"
import Img from "gatsby-image"
import { Text, SmallHeading, CheckMark } from "../utils"

const SideBySideImageLeft = ({ heading, description, details, image }) => {
  return (
    <div className="py-16 bg-gray-50 overflow-hidden lg:py-10">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-0 lg:max-w-6xl	">
        <div className="relative mt-12 sm:mt-16 lg:mt-10">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="lg:col-start-2">
              <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
                {heading}
              </h4>
              {description && (
                <p className="mt-3 text-lg leading-7 text-gray-500">
                  {description}
                </p>
              )}
              <ul className="mt-10">
                {details.map(item => {
                  return (
                    <li className="list-none" key={item._key}>
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <CheckMark />
                        </div>
                        <div className="ml-4">
                          <SmallHeading>{item.heading}</SmallHeading>
                          <Text>{item.description}</Text>
                        </div>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>

            <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
              <Img
                fluid={image.asset.fluid}
                alt={image.alt}
                className="relative mx-auto rounded-md w-11/12 md:w-10/12"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SideBySideImageLeft
